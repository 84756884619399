<template>
  <div class="jslist-style">
    <titles :title="$t('text.t393')" />
    <div class="list">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="$t('text.t39')"
        :offset="10"
        @load="getlist"
      >
        <div class="item between-center" v-for="item in list" :key="item.id">
          <div class="left">
            <div class="row1 start-center">
              <div class="t1">#{{item.tokenid}}</div>
              <div class="t2">{{$t('text.t406')}}</div>
            </div>
            <div class="row2">{{$parseTime(item.createtime)}}</div>
          </div>
          <div class="right">{{$t('text.t406')}} {{item.price}} U</div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import titles from "@/components/titles/index.vue";
import { mapState } from "vuex";
export default {
  components: {
    titles,
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      page: 1,
      limit: 20,
      chain:"",
      contract: "",
    };
  },
  computed: {
    ...mapState(["language"]),
  },
  created() {
    const { chain, contract } = this.$route.query;
    this.chain = chain;
    this.contract = contract;
  },
  methods: {
    getlist() {
      let arg = {
        page: this.page,
        limit: this.limit,
        contract: this.contract,
        chainType: this.chain,
        language: this.language,
      };
      this.$http.get("/core/consignment", arg).then((res) => {
        const { current_page, data, last_page } = res.data;
        if (current_page >= last_page) this.finished = true;
        this.page++;
        this.loading = false;
        this.list = [...this.list, ...data];
      });
    },
  },
};
</script>

<style lang="less">
.jslist-style {
  .list {
    .item {
      border-bottom: 1px solid #e1e1e1;
      padding: 18px 20px 13px;
      .left {
        .row1 {
          margin-bottom: 12px;
          .t1 {
            font-size: 15px;
            font-weight: bold;
            color: #323232;
            margin-right: 5px;
          }
          .t2 {
            background: #ffbaeb;
            border-radius: 5px;
            padding: 3px 9px;
            color: #e233e0;
          }
        }
        .row2 {
          font-size: 12px;
          font-weight: 500;
          color: #909090;
        }
      }
      .right {
        font-size: 15px;
        font-weight: bold;
        color: #323232;
      }
    }
  }
}
</style>